import React from 'react';

import Home from 'components/Home';
import Container from 'components/Container';
import Seo from "../components/Seo";
import {Link} from "gatsby";
import arrowLong from "../images/arrow-long.svg";
import neverbounceImage from "../images/neverbounce/neverbounce-banner.png";
import neverbounceImage2 from "../images/feature-neverbounce2.png";
import zoominfoImage from "../images/zoominfo/zoominfo-banner.png";
import zoominfoImage2 from "../images/feature-onboard.png";
import thrivableImage from "../images/feature-thrivable2.png";
import thrivableImage2 from "../images/feature-thrivable-2.png";
import houseImage from "../images/feature-housepartner.png";
import houseImage2 from "../images/feature-housepartner2.png";
import pouriginsImage from "../images/feature-pourigins.png";
import pouriginsImage2 from "../images/feature-pourigins2.png";
import datanyzeImage from "../images/feature-datanyze.png";
import datanyzeImage2 from "../images/feature-datanyze2.png";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.switchImage = this.switchImage.bind(this);
        this.switchLink = this.switchLink.bind(this);
        this.state = {
            currentImage: 0,
            images: [zoominfoImage, neverbounceImage2, thrivableImage, houseImage, pouriginsImage, datanyzeImage, zoominfoImage2, neverbounceImage,thrivableImage2, houseImage2, pouriginsImage2, datanyzeImage2
            ],
            currentLink: 0,
            links: ['/work/zoominfo', '/work/neverbounce', '/work/thrivable', '/work/housepartner', '/work/pourigins', '/work/datanyze',
            ]
        };
    }

    switchImage() {
        if (this.state.currentImage < this.state.images.length - 1) {
            this.setState({
                currentImage: this.state.currentImage + 1
            });
        } else {
            this.setState({
                currentImage: 0
            });
        }
        return this.currentImage;
    }

    switchLink() {
        if (this.state.currentLink < this.state.links.length - 1) {
            this.setState({
                currentLink: this.state.currentLink + 1
            });
        } else {
            this.setState({
                currentLink: 0
            });
        }
        return this.currentLink;
    }

    componentDidMount() {
        setInterval(this.switchImage, 1200);
        setInterval(this.switchLink, 1200);
    }

    render() {
        return (
                <Home pageName="home">
                    <Seo
                            title={'Designer / Developer'}
                    />
                    <Container>
                        <section>
                            <div className="extra-large-wrapper">
                                <div className="mt-6 sm:mt-10 flex justify-between border-t border-b py-4 sm:border-none sm:py-0 items-center sm:items-start">
                                    <h1 className="font-serif text-left text-4xl sm:text-9xl leading-none sm:-mt-4"><span className="block"><span className="sm:border-b-8 inline-block sm:inline">Carly</span></span><span className="block mt-1 sm:mt-8"><span className="sm:border-b-8 inline-block sm:inline">Bartel</span></span></h1>
                                    <ul className="main-nav flex flex-row w-full text-right justify-end">
                                        <li className="">
                                            <Link to="/" className="border hover:bg-green rounded-full font-mono duration-200 py-2 px-3 sm:px-6 block text-xs" activeClassName="active" ><span>01 </span>Home</Link>
                                        </li>
                                        <li className="ml-2 sm:ml-4">
                                            <Link to="/about" className="border hover:bg-green rounded-full font-mono duration-200 py-2 px-3 sm:px-6 block text-xs" activeClassName="active"><span className="">02 </span>About</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="flex justify-between flex-col lg:flex-row-reverse mt-8 sm:mt-24">
                                    <h1 className="font-serif lg:text-right text-6xl sm:text-9xl leading-none"><span className="block"><span className="sm:border-b-8 inline">Designer</span></span><span className="block mt-1 sm:mt-8"><span className="inline sm:border-b-8 mt-2">Developer</span></span></h1>
                                    <div>
                                        <h3 className="text-xl sm:text-3xl text-left max-w-md leading-1 mt-4 sm:mt-12 lg:mt-0">Providing clarity to interfaces, identities and ideas.</h3>
                                        <img src={arrowLong} alt="scroll" className="mt-6"/>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mt-16 sm:mt-32 mb-32">
                            <div className="extra-large-wrapper">
                                <Link to={this.state.links[this.state.currentLink]}>
                                    <img
                                            src={this.state.images[this.state.currentImage]}
                                            alt="project images"
                                    />
                                </Link>
                                <ul className="project-list mt-16 sm:mt-32">
                                    <li>
                                        <Link to="/work/zoominfo" id="zoominfo">
                                            <h2 className="h1 font-serif text-4xl md:text-7xl">ZoomInfo</h2>
                                            <div className="hidden-menu-text">
                                                <span className="small-uppercase-text">brand identity, design</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/work/neverbounce" id="neverbounce">
                                            <h2 className="h1 font-serif text-4xl md:text-7xl">NeverBounce</h2>
                                            <div className="hidden-menu-text">
                                                <span className="small-uppercase-text">brand identity, design, development</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/work/thrivable" id="pourigins">
                                            <h2 className="h1 font-serif text-4xl md:text-7xl">Thrivable</h2>
                                            <div className="hidden-menu-text">
                                                <span className="small-uppercase-text">brand identity, design</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/work/housepartner" id="housepartner">
                                            <h2 className="h1 font-serif text-4xl md:text-7xl">HousePartner</h2>
                                            <div className="hidden-menu-text">
                                                <span className="small-uppercase-text">brand identity, design, development</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/work/pourigins" id="pourigins">
                                            <h2 className="h1 font-serif text-4xl md:text-7xl">Pourigins</h2>
                                            <div className="hidden-menu-text">
                                                <span className="small-uppercase-text">brand identity</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/work/datanyze" id="pourigins">
                                            <h2 className="h1 font-serif text-4xl md:text-7xl">Datanyze</h2>
                                            <div className="hidden-menu-text">
                                                <span className="small-uppercase-text">design, development</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </Container>
                </Home>
        );
    }
}

export default Index;